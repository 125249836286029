import { useEffect, useRef, useState } from 'react';
import tw, { css, styled } from 'twin.macro';
import { Link } from 'gatsby';
import { Inner } from './';
import { ArrowIcon } from './Icons';
import RibbonCard, { LastCardCTA } from './RibbonCard';

const cardListStyles = css`
  height: 390px;
  transition: transform 300ms ease 0s;
  ${tw`overflow-x-scroll lg:(overflow-x-visible) opacity-0 w-full flex flex-nowrap mx-auto items-end lg:px-14 2xl:max-w-8xl text-white`}
  @media screen and (max-width: 1024px) {
    height: 460px;
  }
`;

const sectionStyles = tw`relative py-20 overflow-hidden px-0 sm:(px-0)`;
const Title = tw.h2`text-xl font-semibold text-blue-dark mb-2 lg:m-0`;

const customRibbonStyles = css`
  ${tw`px-0`}
  a,
  ${Title} {
    color: white;
  }
  ${Title}  {
    ${tw`relative`}
    &:hover::after {
      transform: scaleY(2);
    }
    &::after {
      content: '';
      width: calc(100% + 8px);
      height: calc(100%);
      left: -2px;
      bottom: -1px;
      transform: scaleY(0.1);
      ${tw`absolute bg-red origin-bottom z-minus-1 transition-transform ease-out duration-500`};
    }
  }
`;

const seeAllStyles = css`
  ${tw`flex flex-row items-center cursor-pointer mt-5 text-blue-dark sm:(mt-0)`}
  svg {
    ${tw`transform origin-left scale-100 duration-500`}
  }
  :hover svg {
    ${tw`scale-150`}
  }
`;

const SliderNavButton = styled.button(({ next, show }) => [
  tw`hidden lg:(absolute top-1/2 z-10 bg-yellow text-blue-dark h-10 w-10 rounded-full border-0 flex-center hover:bg-white)`,
  css`
    transition: all 0.4s cubic-bezier(0.5, 0, 0.5, 1);
    svg {
      transform: rotate(${next ? 0 : 180}deg);
    }
  `,
  css`
    transform: scale(${show ? 1 : 0});
  `,
  next
    ? css`
        right: 80px;
      `
    : css`
        left: 80px;
      `,
]);

const RelatedRibbon = ({ articleType, posts, title, blogFrontPage, ...rest }) => {
  const cardRef = useRef();
  const [x, setX] = useState(0);
  const [slides, setSlides] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (cardRef?.current && posts) {
      const cardW = cardRef.current?.getBoundingClientRect().width;
      const cardsCount = posts.length;

      const px = cardW * 3;
      const setsOf3 = Math.floor(cardsCount / 3);
      const hasRemainders = cardsCount / 3 - setsOf3 !== 0;
      const remainderPx = hasRemainders && Math.round(cardsCount / 3 - setsOf3) === 0 ? 307 : 700;

      const arr = [];
      for (let i = 0; i < setsOf3; i++) arr.push(px);
      const finalCTACardW = cardW;
      arr.push(hasRemainders ? finalCTACardW + remainderPx : finalCTACardW);

      setSlides(arr);
    }
  }, [cardRef, posts]);

  const onSliderNav = (direction) => {
    let px;
    if (direction === 'next') {
      const next = currentPage + 1;
      px = x - slides[next];
      setCurrentPage(next);
    } else {
      const prev = currentPage - 1;
      px = x + slides[currentPage];
      setCurrentPage(prev);
    }
    setX(px);
  };

  let seeAllPosts;

  switch (articleType) {
    case 'Article':
      seeAllPosts = 'articles';
      break;
    case 'Tech Talent':
      seeAllPosts = 'tech-talents';
      break;
    case 'Company Case':
      seeAllPosts = 'company-cases';
      break;
  }

  return (
    <section css={sectionStyles} {...rest}>
      <Inner
        css={blogFrontPage && customRibbonStyles}
        tw="text-white flex flex-col justify-between mx-5 lg:(flex-row px-0 mb-24 max-w-5xl mx-20) xl:(px-8 max-w-7xl mx-auto) 2xl:px-0 "
      >
        {blogFrontPage ? (
          <Link to={`/${seeAllPosts}/`} css={seeAllStyles}>
            <Title>{title}</Title>
          </Link>
        ) : (
          <Title>{title || 'You may also be interested in'}</Title>
        )}
        <Link to={`/${seeAllPosts}/`} css={seeAllStyles}>
          <span tw="mr-4">See all {articleType}s</span>
          <ArrowIcon />
        </Link>
      </Inner>
      <SliderNavButton
        onClick={() => onSliderNav()}
        show={currentPage !== 0}
        disabled={currentPage === 0}
      >
        <ArrowIcon />
      </SliderNavButton>
      <SliderNavButton
        onClick={() => onSliderNav('next')}
        show={currentPage < slides?.length - 1}
        disabled={currentPage > slides?.length - 1}
        next
      >
        <ArrowIcon />
      </SliderNavButton>
      <ul
        css={[cardListStyles, posts && tw`opacity-100`]}
        style={{ transform: `translateX(${x}px)` }}
      >
        {posts?.map((card) => (
          <RibbonCard {...card} articleType={articleType} key={card.slug} ref={cardRef} />
        ))}
        <RibbonCard isFinalCardCTA articleType={articleType} slug={seeAllPosts} />
      </ul>
    </section>
  );
};

export default RelatedRibbon;
