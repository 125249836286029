import { forwardRef } from 'react';
import tw, { css } from 'twin.macro';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
// import Link from 'next/link';
// import Image from 'next/image';
import { ArrowIcon } from './Icons';
import ContentLabel from './ContentLabel';

const overlayStyles = css`
  position: absolute;
  height: 100%;
  right: -1px;
  left: -1px;
  bottom: 0;
  opacity: 0.9;
  background: linear-gradient(180deg, rgba(32, 48, 117, 0) 16%, #0a1236 70%);
`;

export const ribbonCardStyles = css`
  transform-origin: top left;
  ${tw`relative mb-12 md:mb-16`}

  :nth-of-type(odd) .image-wrapper {
    height: 340px;
  }

  a {
    margin: 0 20px;
    border-radius: 2px;
    min-width: 28%;
    height: 100%;
    display: block;
    box-shadow: 0 20px 20px -20px transparent;
    position: relative;
    transform: perspective(1000px) rotateX(0);
    transform-style: preserve-3d;
    transition: all 200ms cubic-bezier(0.5, 1, 0.5, 1);
    cursor: pointer;

    @media screen and (max-width: 768px) {
      min-width: 75%;
      margin: 0 10px;
    }

    :hover {
      transform: perspective(1000px) rotateX(5deg);
      box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.6);
      color: inherit;
    }
    :nth-of-type(odd) img {
      object-position: center 30% !important;
    }
  }
`;

const RibbonCard = forwardRef(
  (
    {
      expert,
      ribbonImage,
      title,
      ribbonCaption,
      slug,
      articleType,
      isFinalCardCTA,
      contentNode,
      ...rest
    },
    ref
  ) => {
    const imageData = ribbonImage?.gatsbyImageData || ribbonImage?.techTalentImage?.gatsbyImageData;

    if (isFinalCardCTA) {
      return (
        <li css={ribbonCardStyles}>
          <Link to={`/${slug}`}>
            <div tw="w-96 h-96 bg-blue-dark flex flex-col items-center justify-center">
              <span tw="text-white text-2xl font-semibold mb-4 uppercase">
                See all {articleType}s
              </span>
              <ArrowIcon tw="text-red" size={46} />
            </div>
          </Link>
        </li>
      );
    }

    return (
      <li css={ribbonCardStyles} ref={ref} {...rest}>
        <Link to={`/${slug}`} tw="bg-blue-dark overflow-hidden">
          <ContentLabel label={articleType} inset />
          <div tw="absolute p-5 pr-8 z-10 h-full flex items-end">
            <div>
              <h3 tw="text-base font-semibold m-0 leading-tight">{title}</h3>
              <h4 tw="text-sm mb-2 mt-1 font-normal">{ribbonCaption}</h4>
              <ArrowIcon />
            </div>
          </div>
          <div className="image-wrapper" tw="w-96 h-96 relative text-transparent">
            <GatsbyImage
              image={imageData}
              alt={ribbonImage.alt || ribbonImage.techTalentImage?.alt || title}
            />
          </div>
          <div css={overlayStyles} />
        </Link>
      </li>
    );
  }
);

export default RibbonCard;
